import Debugging from "assets/icons/debugging.svg";
import TransactionsIcon from "components/Icons/TransactionsIcon";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import { ReactNode } from "react";

import AdminIco from "./icons/admin.svg";
import ConstructorIco from "./icons/constructor.svg";
import HomeIco from "./icons/home.svg";
import PaymentsIco from "./icons/payments.svg";
import ReportIco from "./icons/report.svg";
import WalletIco from "./icons/wallet.svg";
import styles from "./Menu.module.scss";
import MenuPageItem from "./MenuPageItem";

export type MenuPageType = {
	readonly link: string;
	readonly title: string;
	readonly icon: ReactNode;
	readonly groups?: ReadonlyArray<MenuGroupType>;
};

export type MenuGroupType = {
	readonly elements: ReadonlyArray<MenuElementType>;
	readonly title?: string;
};

export type MenuElementType = {
	readonly link: string;
	readonly title: string;
	readonly isVisible: boolean;
};

const Menu = () => {
	const { t } = useTranslation();
	const { hasRole, user } = useAuthContext();

	const home: MenuPageType = {
		link: "/",
		title: t(`Home`),
		icon: <HomeIco />,
	};

	const settlements: MenuPageType = {
		link: "payments",
		title: t("Payments"),
		icon: <PaymentsIco />,
		groups: [
			{
				title: t("Payments"),
				elements: [
					{
						link: "stockpiling",
						title: t("Stockpilings"),
						isVisible: hasRole(Roles.STOCKPILING_LIST),
					},
					{
						link: "withdrawals",
						title: t("Withdrawals"),
						isVisible: hasRole(Roles.WITHDRAWALS_VIEW),
					},
				],
			},
			{
				elements: [
					{
						link: "balances/accounts",
						title: t("Accounts balance"),
						isVisible: hasRole(Roles.ACCOUNTS_BALANCE),
					},
				],
			},
			{
				title: t("Postbacks"),
				elements: [
					{
						link: "postbacks",
						title: t(`Sent`),
						isVisible: hasRole(Roles.POSTBACKS),
					},
					{
						link: "postbacks/not-accepted",
						title: t(`Not accepted`),
						isVisible: hasRole(Roles.POSTBACKS),
					},
				],
			},
		],
	};

	const transactions: MenuPageType = {
		link: "transactions",
		title: t("Transactions"),
		icon: <TransactionsIcon />,
		groups: [
			{
				title: t("Transactions"),
				elements: [
					{
						link: "transactions",
						title: t(`Current`),
						isVisible: hasRole(Roles.TRANSACTION_LIST),
					},
					{
						link: "transactions/possible",
						title: t(`Possible`),
						isVisible: hasRole(Roles.TRANSACTION_MANAGEMENT),
					},
					{
						link: "transactions/archived",
						title: t(`Archived`),
						isVisible: hasRole(Roles.ARCHIVE_TRANSACTION_VIEW),
					},
				],
			},
		],
	};

	// https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841
	// const payments: MenuPageType = {
	// 	link: "wallets",
	// 	title: t("Wallets"),
	// 	icon: <WalletIco />,
	// 	groups: [
	// 		{
	// 			title: t("Wallets"),
	// 			elements: [
	// 				{
	// 					link: "wallets",
	// 					title: t(`Working`),
	// 					isVisible: hasRole(Roles.WALLET_LIST),
	// 				},
	// 				{
	// 					link: "wallets/disabled",
	// 					title: t(`Disabled`),
	// 					isVisible: hasRole(Roles.WALLETS_DISABLED),
	// 				},
	// 				{
	// 					link: "wallets/archived",
	// 					title: t(`Archived`),
	// 					isVisible: hasRole(Roles.ARCHIVED_WALLETS_VIEW),
	// 				},
	// 				{
	// 					link: "wallets/statistic",
	// 					title: t("Statistic"),
	// 					isVisible: hasRole(Roles.WALLET_LIST),
	// 				},
	// 			],
	// 		},
	// 	],
	// };

	const reports: MenuPageType = {
		link: "reports",
		title: t("Reports"),
		icon: <ReportIco />,
		groups: [
			{
				title: t("Reports"),
				elements: [
					{
						link: "reports",
						title: t("Reports"),
						isVisible:
							hasRole(Roles.TRANSACTION_REPORTS) ||
							hasRole(Roles.LABEL_HISTORY_REPORT) ||
							hasRole(Roles.COMMISSION_REPORT) ||
							hasRole(Roles.WITHDRAWAL_REQUEST_REPORT) ||
							hasRole(Roles.SETTLEMENT_OPERATIONS_REPORT),
					},
					{
						link: "reports/autoreports",
						title: t(`Autoreports`),
						isVisible: hasRole(Roles.MANAGE_AUTO_GENERATING_REPORTS),
					},
					{
						link: "wallets-load",
						title: t("Wallets' load"),
						isVisible: hasRole(Roles.WALLETS_LOAD),
					},
					{
						link: "change-logs",
						title: t("Change Log"),
						isVisible: hasRole(Roles.VIEW_CHANGE_LOG),
					},
				],
			},
		],
	};

	const debugging: MenuPageType = {
		link: "debugging",
		title: t(`Debugging`),
		icon: <Debugging />,
		groups: [
			{
				title: t(`Debugging`),
				elements: [
					{
						link: "proxies",
						title: t("Proxy"),
						isVisible: false, // hasRole(Roles.PROXY_LIST),
					},
					{
						link: "logs",
						title: t("Log list"),
						isVisible: hasRole(Roles.LOGS),
					},
					{
						link: "logs/withdrawals-logs",
						title: t(`Withdrawals logs`),
						isVisible: hasRole(Roles.LOGS),
					},
				],
			},
		],
	};

	const constructor: MenuPageType = {
		link: "payment-systems",
		title: t("Constructor"),
		icon: <ConstructorIco />,
		groups: [
			{
				title: t("Constructor"),
				elements: [
					{
						link: "payment-systems",
						title: t(`Payment systems`),
						isVisible: hasRole(Roles.MANAGE_PAYMENTS_CONSTRUCTOR),
					},
					{
						link: "payment-systems/tariffs",
						title: t("Tariffs"),
						isVisible: hasRole(Roles.MANAGE_PAYMENTS_CONSTRUCTOR),
					},
					{
						link: "payment-systems/translates",
						title: t("Translations"),
						isVisible: hasRole(Roles.MANAGE_PAYMENTS_CONSTRUCTOR),
					},
					{
						link: "payment-systems/plugin",
						title: t("Plugin"),
						isVisible: hasRole(Roles.MANAGE_PAYMENTS_CONSTRUCTOR),
					},
				],
			},
		],
	};

	const administration: MenuPageType = {
		link: "admin",
		title: user?.name,
		icon: <AdminIco />,
		groups: [
			{
				title: t("Settings"),
				elements: [
					{
						link: "settingsGroup/profile",
						title: t("Profile"),
						isVisible: true,
					},
					{
						link: "settingsGroup/system",
						title: t(`System`),
						isVisible: hasRole(Roles.VIEW_SYSTEM_SETTINGS),
					},
					{
						link: "settingsGroup/private",
						title: t(`Confidential`),
						isVisible: hasRole(Roles.PRIVATE_SETTINGS_VIEW),
					},
					{
						link: "settingsGroup/plugin",
						title: t(`Plugin`),
						isVisible: hasRole(Roles.PLUGIN_SETTINGS) || hasRole(Roles.MEDIA_SETTINGS),
					},
				],
			},
			{
				elements: [
					{
						link: "logout",
						title: t("Logout"),
						isVisible: true,
					},
				],
			},
		],
	};

	const elements: ReadonlyArray<MenuPageType> = [
		home,
		transactions,
		settlements,
		// https://confluence.fixmost.com/pages/viewpage.action?pageId=111613841
		// payments,
		reports,
		constructor,
		debugging,
		administration,
	];

	const menuItemsGenerator = (elements: ReadonlyArray<MenuPageType>) => {
		const readyElements: readonly ReactNode[] = [];

		elements.forEach((elem, i) => {
			if (elem.groups && elem.groups.filter((group) => group.elements.some((elem) => elem.isVisible)).length > 0) {
				// @ts-ignore
				readyElements.push(<MenuPageItem key={i} item={elem} />);
			}
		});

		return readyElements;
	};

	return <div className={styles.Menu}>{menuItemsGenerator(elements)}</div>;
};

export default Menu;
