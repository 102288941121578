import Api from "api";
import { WalletListItem } from "api/walletGroup";
import Filter from "components/Filter";
import RightModal from "components/Modals/RightModal";
import SideModalContent from "components/SideModalContent";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import constructQueryStringParams from "helpers/constructQueryStringParams";
import ReloadButton from "modules/Postbacks/components/ReloadButton";
import { LogsListColumns } from "modules/WalletsDebugging/LogsList/components/LogsListColumns";
import LogsListContextProvider, {
	useLogsListContext,
} from "modules/WalletsDebugging/LogsList/contexts/LogsListContext";
import useLoadLogList from "modules/WalletsDebugging/LogsList/hooks/useLoadLogList";
import useLoadLogsCategoryList from "modules/WalletsDebugging/LogsList/hooks/useLoadLogsCategoryList";
import useGetWallets from "modules/WalletsDebugging/WithdrawalsLogsList/hooks/useGetWallets";
import { Loader, useTranslation } from "pay-kit";
import { Table } from "pay-kit";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fromLocaleDate, notLaterThanToday } from "utils/date";
import { errorsMap } from "utils/enums";
import { rejectSettlement } from "utils/filterSettlement";
import { getUniqueWallets } from "utils/getUniqueWallets";
import useURLFilters from "utils/hooks/useURLFilters";
import useURLState from "utils/hooks/useURLState";

import styles from "./logsList.module.scss";

const LogsList = () => {
	const { walletHashId, setWalletHashId, id } = useLogsListContext();
	const { walletTypes, isLoading } = useContext(WalletTypesContext);
	const walletsAPI = useGetWallets(false);
	const logsCategoryAPI = useLoadLogsCategoryList();
	const logsAPI = useLoadLogList();

	const { t } = useTranslation();

	const params = new URLSearchParams(window.location.search);
	const identifier = params.get("identifier") || ``;
	const [isFetchedWithId, setFetched] = useState(false);
	const [identifierWallet, setIdentifierWallet] = useState<WalletListItem | null>(null);

	const navigate = useNavigate();
	const [page, setCurrentPage] = useURLState<number>("page", logsAPI.data?.current_page || 1);
	const [limit, setLimit] = useURLState<number>("limit", logsAPI.data?.per_page || 10);
	const [filters, setFilters] = useURLFilters({
		dateRangeFields: ["dateRange"],
	});

	const [walletPage, setWalletPage] = useState(1);
	const [accumulatedWallets, setAccumulatedWallets] = useState<readonly WalletListItem[]>([]);
	const [walletIdentifier, setWalletIdentifier] = useState<string | null>(null);

	const filtersRef = useRef<FiltersType | null>(null);

	useEffect(() => {
		if (filtersRef.current) {
			localStorage.setItem("filters", JSON.stringify({ ...filters, wallet_hash_id: identifier }));
		} else {
			filtersRef.current = filters;
		}

		return () => {
			localStorage.removeItem("filters");
		};
	}, [filters]);

	useEffect(() => {
		const storedFilters = JSON.parse(localStorage.getItem("filters") || "{}");
		if (Object.keys(storedFilters).length) setFilters(storedFilters);
	}, []);

	useEffect(() => {
		setAccumulatedWallets((acc) => {
			const wallets = [...acc, ...(walletsAPI.list || [])];

			if (identifierWallet) {
				wallets.push(identifierWallet);
			}

			return getUniqueWallets(wallets);
		});
	}, [walletsAPI.list, identifierWallet]);

	useEffect(() => {
		const params = {
			page: walletPage,
			limit: 10,
		};
		if (walletIdentifier) {
			params.identifier = walletIdentifier;
		}
		walletsAPI.fetchWallets(params);
	}, [walletPage, walletIdentifier]);

	const walletsNumbersOptions = accumulatedWallets.map((wallet) => ({
		value: wallet.hash_id,
		label: wallet.identifier,
	}));

	const walletTypeOptions = rejectSettlement(walletTypes)?.map(({ name, code }) => ({ value: code, label: name }));
	const walletCategoryListOptions = logsCategoryAPI.list?.map(({ text, value }) => ({ value, label: text }));

	const handleShowAllTransactionClick = (walletHashID: string) => {
		navigate(`/transactions?wallet_hash_id=${walletHashID}`);
	};

	const OnloadLogList = () => {
		const { dateRange, ...rest } = filters;

		const rawParams = {
			...rest,
			page,
			limit,
		};

		if (dateRange) {
			rawParams.date_from = fromLocaleDate(dateRange?.from.split(" ")[0]);
			rawParams.date_to = fromLocaleDate(dateRange?.from.split(" ")[0]);
		}

		const params = constructQueryStringParams(rawParams);

		logsAPI.load(params);
	};

	useEffect(() => {
		if (identifier) {
			Api.wallet.getList({ identifier }).then((res) => {
				if (res.status === "success") {
					setIdentifierWallet(res.data[0]);
					return res;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			}).catch((err) => {
				console.log(err);
			});
		}
	}, [identifier]);

	useEffect(() => {
		if (identifier && !isLoading && (walletsNumbersOptions?.length || 0) > 0 && !isFetchedWithId) {
			setFilters((prev) => ({
				...prev,
				wallet_hash_id: walletsNumbersOptions?.find((option) => option.label === identifier)?.value,
			}));
			setFetched(true);
		}
	}, [identifier, walletsNumbersOptions?.length, isLoading, logsAPI.isLoading, isFetchedWithId]);

	useEffect(() => {
		if (Object.keys(filters).length) OnloadLogList();
		else logsAPI.load(constructQueryStringParams({ page, limit }));
	}, [page, limit, filters]);

	return (
		<>
			{identifier && !isFetchedWithId ? (
				<>
					<Loader />
				</>
			) : (
				<Filter
					classNames={{ wrapper: styles.logsFilters }}
					values={filters}
					fields={[
						{
							name: "wallet_type",
							placeholder: t("Wallet type"),
							component: "select",
							className: styles.selectField,
							options: walletTypeOptions,
							isLoading,
						},
						{
							name: "wallet_hash_id",
							placeholder: t("Wallet number"),
							component: "select",
							className: styles.selectField,
							options: walletsNumbersOptions,
							isLoading: walletsAPI.isLoading,
							onMenuScrollToBottom: () => {
								if (walletsAPI.paginationInfo && walletsAPI.paginationInfo?.total > 10) {
									setWalletPage((p) => p + 1);
								}
							},
							onInputChange: (value) => {
								if (value) {
									setAccumulatedWallets([]);
								}
								setWalletIdentifier(value);
								setWalletPage(1);
							},
						},
						{
							name: "category",
							placeholder: t("Category"),
							component: "select",
							className: styles.selectField,
							options: walletCategoryListOptions,
							isLoading: logsCategoryAPI.isLoading,
						},
						{
							name: "dateRange",
							toPlaceholder: t("To date"),
							fromPlaceholder: t("From date"),
							label: t("Activation"),
							component: "dateRange",
							dateFormat: "DD.MM.YYYY",
							blockPredicate: notLaterThanToday,
						},
					]}
					onApply={setFilters}
				/>
			)}

			<div className={styles.logListTableWrapper}>
				<Table
					className={styles.logListTable}
					rowKey={"id"}
					data={logsAPI.data?.data || []}
					columns={LogsListColumns}
					isLoading={logsAPI.isLoading}
					paginator={{
						currentPage: page,
						setCurrentPage,
						limit,
						setLimit,
						lastAvailablePage: logsAPI.data?.last_available_page || 1,
						prefixElement: (
							<ReloadButton
								data-test-id="PPy7FPwz5A06ApnarXH8_"
								isLoading={logsAPI.isLoading}
								onClick={OnloadLogList}
							/>
						),
						className: styles.paginator,
						bottomPaginatorProps: {
							className: styles.bottomPaginator,
						},
					}}
				/>

				<RightModal
					bodyClassName={styles.logListModal}
					heading={t("Wallet")}
					isVisible={!!walletHashId}
					onClose={() => setWalletHashId(undefined)}
					onShow={() => null}
				>
					<SideModalContent
						id={id}
						walletHashId={walletHashId}
						showAllTransactionsForWallet={handleShowAllTransactionClick}
					/>
				</RightModal>
			</div>
		</>
	);
};

export default () => (
	<LogsListContextProvider>
		<LogsList />
	</LogsListContextProvider>
);

export type FiltersType = {
	readonly wallet_type?: string;
	readonly wallet_id?: number;
	readonly category?: string;
	readonly dateRange?: { readonly from: string; readonly to: string };
};
