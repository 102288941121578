import {fetchOldApi} from "api/fetch";

export type ProxyType = {
	readonly id: number;
	readonly uuid: string;
	readonly ip: string;
	readonly port: number;
	readonly username: string;
	readonly password: string;
	readonly status: number;
	readonly is_checking: number;
	readonly created_at: string;
	readonly last_checked_at: string;
	readonly updated_at: string;
	readonly type: string;
	readonly country_code: string;
	readonly wallets: Record<string, readonly string[]>;
}

export type ProxyResponse = {
	readonly proxies: readonly ProxyType[]; 
}

export const proxyGroup = {
	getProxies: () => fetchOldApi<ProxyResponse>("/proxy/list"),
};