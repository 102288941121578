import { PayKitForm, useTranslation } from "@paykassma/pay-kit";
import { IPossibleTransaction, TransactionTypeEnum } from "api/transactionGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import React, { useContext, useEffect } from "react";
import { rejectSettlement } from "utils/filterSettlement";

import useCreateManualTransaction from "./hooks/useCreateManualTransaction";
import useGetPossibleTransactions from "./hooks/useGetPossibleTransactions";
import useWalletTypesLimits from "./hooks/useWalletTypesLimits";
import LeftArrowIcon from "./icons/leftArrow.svg";
import RightArrowIcon from "./icons/rightArrow.svg";
import styles from "./NewTransactionForm.module.scss";
import PossibleTransactonSelect from "./PossibleTransactonSelect";
import { isAmountInRange, isValidAmount, notLaterThanToday, parseAPIResponseErrorsFormat,requiredFieldValidation } from "./utils";

interface INewTransactionFormProps {
	readonly type: TransactionTypeEnum | undefined;
	readonly onSuccess: () => void;
	readonly closeModal: () => void;
}

export type FormStateType = {
	readonly type: TransactionTypeEnum | undefined;
	readonly currentStep: FormStepsEnum;
	readonly wallet_type?: string;
	readonly transaction_id?: string;
	readonly selectedPossibleTrId?: number | string;
	readonly activate: boolean;
	readonly currency_code?: string;
	readonly wallet_id?: number;
	readonly amount?: string;
};

export enum FormStepsEnum {
	first = "firstStep",
	possibleTransactionFound = "possibleTransactionStep",
	second = "secondStep",
}

const NewTransactionForm: React.FC<INewTransactionFormProps> = ({ type, onSuccess, closeModal }) => {
	const { t } = useTranslation();
	const walletTypesContext = useContext(WalletTypesContext);
	const { walletTypes } = walletTypesContext;
	const walletTypesLimitsAPI = useWalletTypesLimits();
	const formDataAPI = PayKitForm.useFormDataAPI<FormStateType>({
		initialState: {
			currentStep: type !== TransactionTypeEnum.DEBUG ? FormStepsEnum.first : FormStepsEnum.second,
			activate: false,
			type,
		},
	});

	const { formState } = formDataAPI;

	useEffect(() => {
		walletTypesLimitsAPI.load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showOnlyInSteps = (steps: readonly string[]) => (children) =>
		<div style={{ display: steps.includes(formState.currentStep) ? "block" : "none" }}>{children}</div>;

	const possibleTransactionsAPI = useGetPossibleTransactions({
		onSuccess: (transactions) => {
			if (transactions.length > 0) {
				formDataAPI.setValue("currentStep", FormStepsEnum.possibleTransactionFound);
			} else {
				formDataAPI.setValue("currentStep", FormStepsEnum.second);
				formDataAPI.setValue("activate", false);
			}
		},
	});

	const createManualTransactionAPI = useCreateManualTransaction({
		onSuccess: () => {
			onSuccess();
			closeModal();
			window.pushAlert({
				description: t("Data added"),
				type: "success",
			});
		},
		onError: (errors) => {
			formDataAPI.setExternalErrors(parseAPIResponseErrorsFormat(errors));
		},
	});

	const walletTypesOptions = rejectSettlement(walletTypes).map(({ name, code }) => ({ label: name, value: code }));

	const getSupportedCurrencies = (walletType: string) =>
		walletTypes.find((wt) => wt.code === walletType)?.supported_currencies || [];

	const getSupportedCurrenciesOptions = (walletType: string) =>
		getSupportedCurrencies(walletType).map((c) => ({ label: c, value: c }));

	const schema: any = [
		{
			type: "Hidden",
			name: "currentStep",
		},
		{
			type: "Hidden",
			name: "type",
		},
		// STEP 1 – choosing wallet_type & transaction_id
		{
			type: "Group",
			render: showOnlyInSteps(["firstStep", "secondStep"]),
			elements: [
				{
					name: "wallet_type",
					label: t("Wallet type"),
					type: "Select",
					options: walletTypesOptions,
					isLoading: walletTypesContext.isLoading,
					validation: requiredFieldValidation,
					disabled: () => {
						if (formState.type === TransactionTypeEnum.DEBUG) {
							return false;
						}

						return formState.selectedPossibleTrId !== undefined || formState.currentStep === "secondStep";
					},
					placeholder: "",
				},
				{
					name: "transaction_id",
					label: t("Transaction number"),
					type: "TextInput",
					validation: requiredFieldValidation,
					disabled: () => formState.selectedPossibleTrId !== undefined || formState.currentStep === "secondStep",
					existsIf: ({ type }: FormStateType) => type !== TransactionTypeEnum.DEBUG,
				},
				{
					type: "ActionsBlock",
					existsIf: ({ currentStep }: FormStateType) => currentStep === "firstStep",
					elements: [
						{
							type: "Button",
							label: (
								<>
									{t("Next")}
									<RightArrowIcon />
								</>
							),
							onClick: (formState: FormStateType) => {
								if (formDataAPI.errors.wallet_type || formDataAPI.errors.transaction_id) {
									formDataAPI.setTouchedFields(["wallet_type", "transaction_id"]);
								} else {
									possibleTransactionsAPI.load({
										wallet_type: formState.wallet_type as string,
										transaction_id: formState.transaction_id as string,
									});
								}
							},
							isLoading: possibleTransactionsAPI.isLoading,
						},
					],
				},
			],
		},
		// STEP 1.1 – Choosing possible transaction if it's found for choosen wallet_type & transaction_id
		{
			type: "Group",
			render: showOnlyInSteps(["possibleTransactionStep"]),
			elements: [
				{
					type: "PossibleTransactionSelect",
					name: "selectedPossibleTrId",
					transactions: possibleTransactionsAPI.list,
				},
				{
					type: "ActionsBlock",
					elements: [
						{
							label: (
								<>
									<LeftArrowIcon /> {t("Back")}
								</>
							),
							type: "Button",
							variant: "secondary",
							onClick: () => {
								formDataAPI.setValue("currentStep", "firstStep");
								formDataAPI.setValue("selectedPossibleTrId", undefined);
							},
						},
						{
							type: "Button",
							label: (
								<>
									{t("Next")}
									<RightArrowIcon />
								</>
							),
							onClick: ({ selectedPossibleTrId }: FormStateType) => {
								formDataAPI.setValue("currentStep", "secondStep");

								if (selectedPossibleTrId !== undefined) {
									const pickedPossibleTransaction = possibleTransactionsAPI.list.find(
										(tr: IPossibleTransaction) => tr.primary_id === selectedPossibleTrId
									);

									formDataAPI.setValue("amount", pickedPossibleTransaction?.amount);
									formDataAPI.setValue("currency_code", pickedPossibleTransaction?.currency_symbol);
									formDataAPI.setValue("label", pickedPossibleTransaction?.label);
									formDataAPI.setValue("merchant_order_id", pickedPossibleTransaction?.merchant_order_id);
									formDataAPI.setValue("activate", true);
								} else {
									formDataAPI.setValue("activate", false);
								}
							},
						},
					],
				},
			],
		},
		// STEP 2
		{
			type: "Group",
			existsIf: ({ currentStep }: FormStateType) => currentStep === "secondStep",
			elements: [
				{
					label: t("Wallet number"),
					type: "TextInput",
					name: "wallet_identifier",
					validation: requiredFieldValidation,
					existsIf: ({ type }: FormStateType) => type === TransactionTypeEnum.APPROVED,
				},
				{
					label: t("Amount"),
					name: "amount",
					type: "TextInput",
					disabled: () => formState.selectedPossibleTrId !== undefined,
					validation: [requiredFieldValidation, isValidAmount, isAmountInRange(walletTypesLimitsAPI.list)],
				},
				{
					label: t("Currency"),
					name: "currency_code",
					type: "Select",
					placeholder: "",
					options: ({ wallet_type }: FormStateType) => getSupportedCurrenciesOptions(wallet_type as string),
					disabled: () => formState.selectedPossibleTrId !== undefined,
					validation: requiredFieldValidation,
				},
				{
					label: t("Counterparty"),
					type: "TextInput",
					name: "exchange_identifier",
					existsIf: ({ type }: FormStateType) => type !== TransactionTypeEnum.DEBUG,
				},
				{
					label: t("Activate"),
					type: formState.selectedPossibleTrId === undefined ? "Toggler" : "Hidden",
					name: "activate",
					disabled: () => formState.selectedPossibleTrId !== undefined,
				},
				{
					label: t("Label"),
					name: "label",
					type: "TextInput",
					existsIf: ({ activate }: FormStateType) => activate === true,
					disabled: () => formState.selectedPossibleTrId !== undefined,
					validation: requiredFieldValidation,
				},
				{
					label: t("Order ID"),
					name: "merchant_order_id",
					type: "TextInput",
					disabled: () => formState.selectedPossibleTrId !== undefined,
					existsIf: ({ activate }: FormStateType) => activate === true,
				},
				{
					type: "ActionsBlock",
					elements: [
						{
							label: (
								<>
									<LeftArrowIcon /> {t("Back")}{" "}
								</>
							),
							type: "Button",
							variant: "secondary",
							existsIf: ({ type }: FormStateType) => type !== TransactionTypeEnum.DEBUG,
							onClick: () => {
								if (formState.selectedPossibleTrId !== undefined) {
									formDataAPI.setValue("currentStep", "possibleTransactionStep");
								} else {
									formDataAPI.setValue("currentStep", "firstStep");
								}
							},
						},
						{
							type: "SubmitButton",
							label: t("Create"),
							isLoading: createManualTransactionAPI.isLoading,
							onSubmit: (formData: FormStateType) => {
								createManualTransactionAPI.create(formData, possibleTransactionsAPI.list);
							},
						},
					],
				},
			],
		},
	];

	const customElements = {
		ActionsBlock: (props: any) => (
			<PayKitForm.Group {...props} render={(children) => <div className={styles.actions}>{children}</div>} />
		),
		PossibleTransactionSelect: (props: any) => (
			<PossibleTransactonSelect
				transactions={props.transactions}
				onChange={props.onChange}
				selectedPossibleTrId={props.value}
				data-test-id="useless-data-test-id"
			/>
		),
	};

	return (
		<PayKitForm.Builder
			schema={schema}
			formDataAPI={formDataAPI}
			customElements={customElements}
			showDebugger={false}
		/>
	);
};

export default NewTransactionForm;
