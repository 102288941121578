import { IWithdrawalDetails, IWithdrawalListItem, WithdrawalStatusENUM, WithdrawalType } from "api/withdrawalGroup";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { Loader, ScrollBox, useTranslation } from "pay-kit";
import React, { useEffect } from "react";

import useWithdrawalAPI from "../../../../hooks/useWithdrawalAPI";
import WithdrawalStatus from "../../../columns/components/WithdrawalStatus";
import WithdrawalTypeComponent from "../../../columns/components/WithdrawalType";
import TextWithCopyButton from "components/TextWithCopyButton";
import styles from "./CommonData.module.scss";
import EditableComment from "./EditableComment";

interface ICommonDataProps {
	readonly id: IWithdrawalListItem["id"];
	readonly refreshHandler: () => void;
}

const CommonData: React.FC<ICommonDataProps> = ({ id, refreshHandler }) => {
	const { t } = useTranslation();
	const withdrawalAPI = useWithdrawalAPI();

	useEffect(() => {
		withdrawalAPI.getWithdrawal(id);
	}, [id]);

	const ROWS_MAP = [
		{
			// id: "wallet_type_logo", title: 'Тип кошелька', render: ({ wallet_type }: IWithdrawalDetails) => {
			id: "wallet_type_logo",
			title: t("Wallet type"),
			render: ({ wallet_type }: IWithdrawalDetails) => {
				return <PaymentSystemLogo id={wallet_type} hideLabel />;
			},
		},
		{
			// id: "wallet_type_label", title: 'Название', render: ({ wallet_type }: IWithdrawalDetails) => {
			id: "wallet_type_label",
			title: t("Name"),
			render: ({ wallet_type }: IWithdrawalDetails) => {
				return <PaymentSystemLogo id={wallet_type} hideLogo />;
			},
		},
		// { id: "created_at", title: 'Дата заявки' },
		{ id: "created_at", title: t("Request date") },
		// { id: "withdrawal_id", title: 'ID Вывода' },
		{
			id: "withdrawal_id",
			title: t("Withdrawal ID"),
			render: ({ withdrawal_id }: IWithdrawalDetails) => {
				return (
					<TextWithCopyButton text={withdrawal_id} dataTestId="withdrawal_id" />
				)
			}
		},
		// { id: "type", title: 'Тип заявки', render: ({ type }: IWithdrawalDetails) => type === WithdrawalType.AUTO ? "Авто" : "Ручная" },
		{
			id: "label",
			title: t("Label"),
			render: ({ label }: IWithdrawalDetails) => {
				return (
					<TextWithCopyButton text={label} dataTestId="label" />
				)
			}
		},
		{
			id: "type",
			title: t("Request type"),
			render: ({ type }: IWithdrawalDetails) => (type === WithdrawalType.AUTO ? t("Auto") : t("Manual")),
		},
		// { id: "is_manually", title: 'Способ вывода', render: ({ is_manually }: IWithdrawalDetails) => is_manually ? "Вручную из ЛК" : "API" },
		{
			id: "is_manually",
			title: t("Withdrawal method"),
			render: ({ is_manually }: IWithdrawalDetails) => (is_manually ? t("Manually") : t("API")),
		},
		// { id: "status", title: 'Статус', render: ({ status }: IWithdrawalDetails) => <WithdrawalStatus status={status} /> },
		{
			id: "status",
			title: t("Status"),
			render: ({ status }: IWithdrawalDetails) => <WithdrawalStatus status={status} />,
		},
		// { id: "is_test", title: 'Вид заявки', render: ({ is_test }: IWithdrawalDetails) => <WithdrawalTypeComponent isTest={is_test} /> },
		{
			id: "is_test",
			title: t("Request kind"),
			render: ({ is_test }: IWithdrawalDetails) => <WithdrawalTypeComponent isTest={is_test} />,
		},
		// { id: "wallet_sender", title: 'Кошелек отправителя' },
		// { id: "wallet_sender", title: t("Wallet sender") },
		{ id: "wallet_sender", title: t("Wallet sender"), render: ({ wallet_sender_identifier }: IWithdrawalDetails) => <span>{wallet_sender_identifier}</span>},
		// { id: "wallet_recipient", title: 'Кошелек получателя' },
		{ id: "wallet_recipient", title: t("Wallet recipient") },
		{ id: "wallet_recipient_name", title: t("User") },
		{
			// id: "amount", title: 'Сумма', render: ({ amount, currency_code }: IWithdrawalDetails) => (
			id: "amount",
			title: t("Amount"),
			render: ({ amount, currency_code }: IWithdrawalDetails) => <Money amount={amount} currencyCode={currency_code} />,
		},
		// { id: "updated_at", title: 'Дата последнего обновления' },
		{ id: "updated_at", title: t("Last update") },
		{
			// id: "comment", title: 'Комментарий', render: ({ comment, id, status }: IWithdrawalDetails) => (
			id: "comment",
			title: t("Comment"),
			render: ({ comment, id, status }: IWithdrawalDetails) => (
				<EditableComment
					id={id}
					comment={comment}
					onSuccess={() => {
						withdrawalAPI.getWithdrawal(id);
						refreshHandler && refreshHandler();
					}}
					isEditable={[WithdrawalStatusENUM.NEW, WithdrawalStatusENUM.PENDING, WithdrawalStatusENUM.ERROR].includes(
						status
					)}
				/>
			),
		},
	];

	if (withdrawalAPI.isLoading) {
		return (
			<div className={styles.CommonData}>
				<Loader />
			</div>
		);
	}

	if (withdrawalAPI.withdrawal === null) {
		return null;
	}

	return (
		<div className={styles.CommonData}>
			<ScrollBox>
				{ROWS_MAP.map((row) => {
					if (withdrawalAPI.withdrawal === null) {
						return null;
					}

					const value = withdrawalAPI.withdrawal[row.id as keyof IWithdrawalDetails];
					const renderedValue = row.render ? row.render(withdrawalAPI.withdrawal) : value;

					return (
						<div key={`${row.id}_${row.title}`} className={styles.row}>
							<div className={styles.title}>{row.title}</div>
							<div className={styles.value}>{renderedValue}</div>
						</div>
					);
				})}
			</ScrollBox>
		</div>
	);
};

export default CommonData;
