import { fetchApi, fetchOldApi } from "./fetch";

const transactionGroup = {
	getList: (queryParams: ITransactionsFilterForm) =>
		fetchOldApi("/transactions", { archiving_status: "withoutArchived", ...queryParams }, "GET"),
	getArchivedList: (queryParams: IArchivedTransactionsFilterForm) =>
		fetchApi("/transactions", { archiving_status: "onlyArchived", ...queryParams }, "GET"),
	getPossibleList: (queryParams: IPossibleTransactionsFilterForm) =>
		fetchApi("/possible-transaction/list", queryParams, "GET"),
	archive: (queryParams: Pick<ICurrentTransaction, "primary_id">) =>
		fetchOldApi(`/archive-transaction/archive/${queryParams.primary_id}`, undefined, "POST"),
	approve: (queryParams: Pick<ICurrentTransaction, "primary_id">) =>
		fetchApi(`/transaction/approve/${queryParams.primary_id}`, undefined, "POST"),
	activate: ({
		primary_id,
		merchant_order_id,
		label,
		transaction_id,
	}: Pick<ICurrentTransaction, "primary_id" | "merchant_order_id" | "label"> & { readonly transaction_id?: string }) =>
		fetchOldApi(
			`/transaction/force-activate/${primary_id}`,
			{
				merchant_order_id,
				label,
				transaction_id,
			},
			"POST"
		),
	createManual: (data: ICreateManualRequestData) => fetchApi(`/transaction/create-manual`, data, "POST"),
	removePossible: (queryParams: Pick<IPossibleTransaction, "primary_id">) =>
		fetchOldApi(`/possible-transaction/remove/${queryParams.primary_id}`, undefined, "DELETE"),
	approvePossible: ({ primary_id, currency, amount, wallet_hash_id }: IApprovePossibleParams) =>
		fetchApi(`/possible-transaction/approve/${primary_id}`, { currency, amount, wallet_hash_id }, "POST"),
	changePossibleStatus: (primary_id: number) =>
		fetchApi(`/possible-transaction/status_change/${primary_id}`, undefined, "POST"),
	setFakeStatus: (primary_id: number, is_fake: boolean) =>
		fetchApi(
			`/transaction/set_fake`,
			{
				is_fake,
				transactions: [primary_id],
			},
			"PUT"
		),
};

export interface ITransactionsFilterForm extends IArchivedTransactionsFilterForm {
	readonly creation_type?: "auto" | "manual"; //Тип транзакции "Авто" или "Ручная"
	readonly wallet_hash_id?: string;
}

export interface IPossibleTransactionsFilterForm {
	readonly date_from?: string;
	readonly date_to?: string;
	readonly label?: string;
	readonly primary_id?: number;
	readonly status?: PossibleTransactionStatusType;
	readonly transaction_id?: string;
	readonly wallet_type?: string;
}

export interface IApprovePossibleParams {
	readonly primary_id: number;
	readonly amount: number;
	readonly currency: string;
	readonly wallet_hash_id: string;
}

export interface ICurrentTransaction {
	readonly primary_id: number;
	readonly id: number; //Номер транзакции
	readonly merchant_order_id: null | any; // Номер заказа
	readonly amount: number; // Сумма
	readonly exchanger_identifier: string; // Контрагент
	readonly exchanger_name: string; // Имя контрагента
	readonly direction: "ingoing" | "outgoing"; // направление тарнзакции
	readonly status: TransactionStatusEnum; //Статус транзакции
	readonly creation_type: "manual" | "auto"; //Тип транзакции
	readonly type: TransactionTypeEnum; //Вид тразакции
	readonly created_at: null | string; // Дата создания
	readonly updated_at: string | null; // Дата обновления
	readonly activated_at: null | string; //Дата активации
	readonly country_id: null | number; // Страна
	readonly total_commission: number; //Комиссия
	readonly wallet_hash_id: string; // ID кошелька
	readonly wallet_type: string; //Тип кошелька
	readonly wallet_identifier: string; //Номер кошелька
	readonly currency_code: string; // код валюты
	readonly postback_send: null | any;
	readonly status_code: null | string | number;
	readonly label: null | string; //Лейбл
	readonly postback_status: 0 | 1 | 2 | 3; //Статус постбека
	readonly postback_message: string;
	readonly postback_id: null | string | number;
	readonly wallet_balance?: number;
	readonly is_fake?: boolean;
	readonly removed_at: string;
}

export interface IArchivedTransaction extends ICurrentTransaction {
	readonly removed_at: string;
	readonly stockpiling_id: number;
}

export interface IPossibleTransaction {
	readonly primary_id: number;
	readonly id: string;
	readonly amount: number;
	readonly currency_symbol: string;
	readonly wallet_type: string;
	readonly status: PossibleTransactionStatusType;
	readonly created_at: string;
	readonly label: string;
	readonly merchant_order_id: string | null | number;
	readonly screenshot: string | null;
	readonly is_manual: 1 | 0;
}

export enum PossibleTransactionStatusType {
	NEW = 0,
	PROCESSED = 1,
}

export interface IArchivedTransactionsFilterForm {
	readonly country_id?: number; //ID страны транзакции
	readonly merchant_order_id?: readonly string[] | string; // Номер заказа
	readonly date_from?: string;
	readonly date_to?: string;
	readonly activated_date_from?: string;
	readonly activated_date_to?: string;
	readonly archiving_date_from?: string;
	readonly archiving_date_to?: string;
	readonly direction?: string;
	readonly exchanger_identifier?: string;
	readonly label?: string;
	readonly postback_status?: number;
	readonly primary_id?: number;
	readonly status?: TransactionStatusEnum;
	readonly transaction_id?: string;
	readonly type?: TransactionTypeEnum;
	readonly wallet_id?: number;
	readonly wallet_type?: string;
	readonly has_label?: "true" | "false";
}

// Cтатусы транзакций
export enum TransactionStatusEnum {
	NOT_ACTIVATED = 0, // Не активирована
	ACTIVATED = 1, // Активирована
	PENDING = 3, // В ожидании (для некоторых ПС)
	IN_PROCESSING = 4, // В обработке (для некоторых ПС)
	CRASHED = 5, // Дала сбой (для некоторых ПС)
	CANCELED = 6, // Отменена (для некоторых ПС)
	REFUSED = 7, // Отклонена
	ERROR = 8, // С ошибкой
	SUSPECT = 9, // Подозрительная
}

// Типы транзакций
export enum TransactionTypeEnum {
	APPROVED = 0, // Подтвержденная
	DEBUG = 1, // Отладочная
	// FORCED = 2, // Принудительная
	INTERNAL = 3, // Внутренняя
}

export interface ITransactionsPaginatorParams {
	readonly limit?: number;
	readonly page?: number;
}

export interface ICreateManualRequestData {
	readonly type: Exclude<TransactionTypeEnum, TransactionTypeEnum.INTERNAL>;
	readonly currency_code: string;
	readonly activate: boolean;
	readonly wallet_identifier: string;
	readonly wallet_type: string;
	readonly transaction_id?: string;
	readonly amount: string;
	readonly exchange_identifier?: string;
	readonly label?: string;
	readonly merchant_order_id?: string;
}

export default transactionGroup;
