import { TransactionTypeEnum } from "api/transactionGroup";
import DropdownButton from "components/DropdownButton";
import { Roles } from "contexts/AuthContext";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { useTranslation } from "pay-kit";
import { Modal } from "pay-kit";
import { useState } from "react";

import styles from './CreateNewTransaction.module.scss';
import HelpToolTip from "./HelpToolTip";
import NewTransactionForm from "./NewTransactionForm";

interface ICreateNewTransactionProps {
	readonly onSuccess: () => void;
}

const CreateNewTransaction = ({ onSuccess }: ICreateNewTransactionProps) => {
	type activeFormId = Exclude<TransactionTypeEnum, TransactionTypeEnum.INTERNAL>;

	const { t } = useTranslation();
	const { hasRole } = useAuthContext();

	const [activeForm, setActiveForm] = useState<activeFormId | undefined>(undefined);

	const TITLES_MAP = {
		[TransactionTypeEnum.APPROVED]: t("Generating confirmed transaction"),
		[TransactionTypeEnum.DEBUG]: t("Generating debug transaction"),
	};

	const createForcedAccess = hasRole(Roles.CREATE_FORCED_TRANSACTION);
	const createDebugAccess = hasRole(Roles.CREATE_FORCED_TRANSACTION);

	return (
		<>
			<Modal
				title={TITLES_MAP[activeForm as activeFormId]}
				isOpen={activeForm !== undefined}
				onClose={() => setActiveForm(undefined)}
				className={styles.modal}
			>
				<NewTransactionForm type={activeForm} onSuccess={onSuccess} closeModal={() => setActiveForm(undefined)} />
			</Modal>
			{(createForcedAccess || createDebugAccess) && <DropdownButton
				options={[
					{
						id: TransactionTypeEnum.APPROVED,
						title: t("Confirmed"),
						onClick: (id) => setActiveForm(id as activeFormId),
						hasAccess: createForcedAccess,
					},
					{
						id: TransactionTypeEnum.DEBUG,
						title: t("Debug"),
						onClick: (id) => setActiveForm(id as activeFormId),
						hasAccess: createDebugAccess,
					},
				].filter((opt) => opt.hasAccess)}
				data-test-id="CreateNewTransactionDropdownButton"
				postfixElement={<HelpToolTip />}
			>
				{t("Generate")}{" "}
			</DropdownButton>}
		</>
	);
};

export default CreateNewTransaction;
